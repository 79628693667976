import EventEmitter from "./EventEmitter.js";

// https://gist.github.com/Alex1990/d462cb9f90ed2b1a9390
const touchHandler = (event) => {
  const touch = event.changedTouches[0];
  const simulatedEvent = new MouseEvent({
    touchstart: "mousedown",
    touchmove: "mousemove",
    touchend: "mouseup"
  }[event.type], {
    bubbles: true, cancelable: true, view: window, detail: 1,
    screenX: touch.screenX, screenY: touch.screenY, clientX: touch.clientX, clientY: touch.clientY,
    ctrlKey: false, altKey: false, shiftKey: false, metaKey: false, button: 0, relatedTarget: null
  });

  touch.target.dispatchEvent(simulatedEvent);
}

export default class Events extends EventEmitter {
  constructor() {
    super();

    window.experience.canvas.addEventListener("click", (e) => {
      this.trigger("click", [e]);
    });

    window.experience.canvas.addEventListener("mousemove", (e) => {
      this.trigger("mousemove", [e]);
    });

    window.experience.canvas.addEventListener("touchstart", touchHandler, { passive: true });
    window.experience.canvas.addEventListener("touchmove", touchHandler, { passive: true });
    window.experience.canvas.addEventListener("touchend", touchHandler, { passive: true });
    window.experience.canvas.addEventListener("touchcancel", touchHandler, { passive: true });
  }
}
