import * as THREE from "three";
import gsap from "gsap";
import BackgroundMaterial from "../materials/Background.js";
import Experience from "../Experience.js";
import { themeColors } from "../../../../javascript/util.js";

export default class Background {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("background");
    }

    this.generateBackground();

    this.time.on("tick", () => {
      this.mesh.material.uniforms.uTime.value = this.time.elapsed / 1000;
    });
  }

  click() {
    this.randomizeBackgroundColor();

    gtag("event", "blob_click");
  }

  mousemove(e) {
    this.updateMousePosition(e);
  }

  generateBackground() {
    const color = sessionStorage.getItem("theme-color") || "#ccc";
    const backgroundGeometry = new THREE.PlaneGeometry(1.25, 1.25);
    const backgroundMaterial = new BackgroundMaterial(color, "#000000");
    const mesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);

    mesh.name = "background";
    mesh.position.z = -0.01;

    this.mesh = mesh;
    this.scene.add(mesh);
  }

  randomizeBackgroundColor() {
    const randomColor = themeColors[(themeColors.length * Math.random()) | 0];
    const currentColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');

    if (randomColor === currentColor) {
      return this.randomizeBackgroundColor();
    }

    const color = new THREE.Color(randomColor);

    document.documentElement.style.setProperty('--theme-color', randomColor);
    sessionStorage.setItem("theme-color", randomColor);

    gsap.to(this.mesh.material.uniforms.uColorFg.value, {
      r: color.r,
      g: color.g,
      b: color.b,
      duration: 2.5,
    });
  }

  updateMousePosition(e) {
    const canvas = e.target;
    const screen = canvas.getBoundingClientRect();
    const x = ((e.clientX - screen.left) * canvas.width) / screen.width;
    const y = ((e.clientY - screen.top) * canvas.height) / screen.height;
    const mousePos = [x, canvas.height - y - 1];

    if (isNaN(x) || isNaN(y)) {
      return;
    }

    gsap.to(this.mesh.material.uniforms.uMouse.value, {
      x: mousePos[0],
      y: mousePos[1],
      duration: 2.5,
      ease: "elastic.out(1, 0.5)",
    });
  }
}
