import Experience from "../Experience.js";

export default class Container {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("container");
    }

    this.resource = this.resources.items["merrimint container"];

    this.generateMesh();
  }

  generateMesh() {
    const gltf = this.resource;

    gltf.scene.position.set(0, -15, 0);
    gltf.scene.scale.set(0.25);

    this.scene.add(gltf.scene);
  }
}
