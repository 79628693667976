import gsap from "gsap";

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const windowResolution = () => {
  return (
    (window.experience.sizes.height * window.experience.sizes.devicePixelRatio) ||
      (document.documentElement.clientWidth * window.devicePixelRatio)
  );
}

const scaleMeshes = (meshes = [], options = {}) => {
  const defaultOptions = {
    isAnimated: true,
    minScale: 0.1,
    maxScale: 1.0
  };

  const meshOptions = {
    ...defaultOptions,
    ...options,
  };

  const meshScale = clamp(
    window.experience.sizes.width / window.experience.sizes.height,
    meshOptions.minScale,
    meshOptions.maxScale
  );

  meshes.forEach((mesh) => {
    if (mesh.material) {
      mesh.material.uniforms.uResolution.value = windowResolution();
    }

    if (meshOptions.isAnimated) {
      gsap.to(mesh.scale, {
        x: meshScale,
        y: meshScale,
        z: meshScale,
        duration: 2.5,
        ease: "elastic.out(1, 0.85)",
      });
    } else {
      mesh.scale.set(meshScale, meshScale, meshScale);
    }
  });
};

export { scaleMeshes };
