import { resetView } from "../../util.js";

class About {
  constructor() {
    this.namespace = "about";
  }

  beforeEnter = (_data) => {
    resetView();
  }
}

export default new About();
