import { resetView } from "../../util.js";

class Contact {
  constructor() {
    this.namespace = "contact";
    this.form;
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const formBody = new URLSearchParams(formData).toString();
    const formMessages = document.getElementById("form-messages");

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: formBody,
    })
    .then((e) => {
      formMessages.classList.add("success");
      formMessages.innerHTML = "<p>Thanks! We'll reach out soon.</p>";
      this.form.reset();
    })
    .catch((error) => {
      formMessages.classList.add("error");
      formMessages.innerHTML =
        "<p>There was a problem submitting the form:</p><p>" + error + "</p>";
    });
  };

  beforeEnter = (_data) => {
    resetView();
  };

  afterEnter = (_data) => {
    const form = document.getElementById("contact-form");

    if (!form) return;

    this.form = form;
    this.form.addEventListener("submit", this.handleSubmit);
  };
}

export default new Contact();
