import gsap from 'gsap';
import Experience from "../Experience.js";
import Environment from "./Environment.js";
import Background from "./Background.js";
import Text from "./Text.js";
import { scaleMeshes } from "../../../../javascript/threejs/Mesh.js";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    this.resources.on("ready", () => {
      this.environment = new Environment();
      this.background = new Background();
      this.text = new Text("m o r p h o s i s");
      this.meshes = [this.background.mesh, this.text.mesh];

      scaleMeshes(this.meshes);

      gsap.to(this.experience.canvas, { opacity: 1, duration: 1.5 });
    });
  }

  update() {}

  resize() {
    scaleMeshes(this.meshes);
  }

  click(_e) {
    if (this.background) this.background.click();
  }

  mousemove(e) {
    if (this.background) this.background.mousemove(e);
  }
}
