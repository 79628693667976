import Experience from "../../../pages/home/app/Experience.js";
import { resetView } from "../../util.js";

class Home {
  constructor() {
    this.namespace = "home";
  }

  initExperience() {
    new Experience(document.getElementById("hero"));
  }

  beforeEnter = (_data) => {
    resetView();
  };

  afterEnter = (_data) => {
    this.initExperience();
  };
}

export default new Home();
