import * as THREE from "three";
import fragmentShader from "../glsl/shaders/displacement/fragment.glsl";
import vertexShader from "../glsl/shaders/displacement/vertex.glsl";

const resolution =
  document.documentElement.clientWidth * window.devicePixelRatio;

export default function (colorFg, colorBg) {
  const uniforms = {
    uTime: { value: 0.0 },
    uResolution: { value: resolution },
    uMouse: { value: new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2) },
    uColorFg: { value: new THREE.Color(colorFg) },
    uColorBg: { value: new THREE.Color(colorBg) }
  };

  const material = new THREE.ShaderMaterial({
    uniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
    side: THREE.DoubleSide
  });

  return material;
}
