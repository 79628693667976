import * as THREE from "three";
import Experience from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;

    this.setInstance();
    this.setControls();
  }

  getAspectRatio() {
    return this.sizes.width / this.sizes.height;
  }

  setInstance() {
    // TODO
    // Do this by-the-books anre remove the left/right settings in resize();
    // new THREE.OrthographicCamera( width / - 2, width / 2, height / 2, height / - 2, 1, 1000 );

    this.instance = new THREE.OrthographicCamera(
      -1 * this.getAspectRatio(),
      1 * this.getAspectRatio(),
      1,
      -1,
      0.1,
      5
    );

    this.instance.position.set(0, 0, 1);
    this.scene.add(this.instance);
  }

  setControls() {
    this.controls = new OrbitControls(this.instance, this.canvas);
    this.controls.camera = this;
    this.controls.enableDamping = true;
    this.controls.enablePan = false;
    this.controls.enableZoom = false;

    this.controls.enabled = false;
  }

  resize() {
    this.instance.aspect = this.getAspectRatio();

    this.instance.left = -1 * this.instance.aspect;
    this.instance.right = 1 * this.instance.aspect;

    this.instance.updateProjectionMatrix();
  }

  update() {
    this.controls.update();
  }
}
