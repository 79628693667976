import Experience from "../../../pages/branding/app/Experience.js";
import { resetView } from "../../util.js";

class Branding {
  constructor() {
    this.namespace = "branding";
  }

  initExperience() {
    new Experience(document.getElementById("branding-packaging"));
  }

  beforeEnter = (_data) => {
    resetView();
  };

  afterEnter = (_data) => {
    this.initExperience();
  };
}

export default new Branding();
