import gsap from "gsap";
import * as THREE from "three";
import Experience from "./Experience.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

export default class Camera {
  constructor() {
    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.scene = this.experience.scene;
    this.canvas = this.experience.canvas;
    this.userIdle = true;

    this.setInstance();
    this.setControls();
  }

  handleControls(e) {
    if (e.type === "start" || e.type === "click") {
      this.autoRotate = false;
      this.camera.userIdle = false;
    } else if (e.type === "end") {
      this.camera.userIdle = true;
      this.autoRotate = true;

      setTimeout(() => {
        const duration = 0.95;
        const easeType = 'power2';

        gsap.to(this.camera.instance.position, {
          y: this.camera.defaultCameraPosition.y,
          duration: duration,
          ease: easeType,
        });

        gtag("event", "container_interaction");
      }, 1000);
    }
  }

  getAspectRatio() {
    return (
      this.canvas.parentElement.clientWidth /
      this.canvas.parentElement.clientHeight
    );
  }

  setInstance() {
    this.instance = new THREE.PerspectiveCamera(
      50,
      this.getAspectRatio(),
      1,
      100
    );

    this.instance.position.set(0, 0, 1);
    this.defaultCameraPosition = { ...this.instance.position };
    this.scene.add(this.instance);
  }

  setControls() {
    this.controls = new OrbitControls(this.instance, this.canvas);
    this.controls.autoRotate = true;
    this.controls.autoRotateSpeed *= -0.5;
    this.controls.camera = this;
    this.controls.enableDamping = true;
    this.controls.enablePan = false;
    this.controls.enableZoom = false;
    this.controls.maxDistance = 90;
    this.controls.minDistance = 60;
    this.controls.minPolarAngle = (Math.PI / 2) - 0.75;
    this.controls.maxPolarAngle = Math.PI - 0.75;

    this.controls.addEventListener("start", this.handleControls);
    this.controls.addEventListener("end", this.handleControls);
  }

  resize() {
    this.instance.aspect = this.getAspectRatio();
    this.instance.updateProjectionMatrix();
  }

  update() {
    this.controls.update();
  }
}
