import * as THREE from "three";
import fragmentShader from "../glsl/shaders/text/fragment.glsl";
import vertexShader from "../glsl/shaders/text/vertex.glsl";

export default function (color) {
  const uniforms = {
    uColor: { value: new THREE.Color(color) },
    uResolution: {
      value: document.documentElement.clientWidth * window.devicePixelRatio,
    },
    uTime: { value: 0 },
  };

  const material = new THREE.ShaderMaterial({
    transparent: true,
    uniforms,
    vertexShader: vertexShader,
    fragmentShader: fragmentShader,
  });

  return material;
}
