import { resetView } from "../../util.js";

class Privacy {
  constructor() {
    this.namespace = "privacy";
  }

  beforeEnter = (_data) => {
    resetView();
  };
}

export default new Privacy();
