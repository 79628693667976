import barba from "@barba/core";
import gsap from "gsap";
import About from "./pages/About.js";
import Branding from "./pages/Branding.js";
import Contact from "./pages/Contact.js";
import Home from "./pages/Home.js";
import Privacy from "./pages/Privacy.js";
import Services from "./pages/Services.js";

barba.init({
  debug: false,
  schema: {
    prefix: "data-transition",
    wrapper: "wrapper",
  },
  views: [About, Branding, Contact, Home, Privacy, Services],
  transitions: [
    {
      name: "opacity-transition",
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0,
        });
      },
      enter(data) {
        return gsap.from(data.next.container, {
          opacity: 0,
        });
      },
    },
  ],
});
