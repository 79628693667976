import gsap from 'gsap';

const nav = document.getElementsByTagName('nav')[0];

const navActive = () => nav.classList.contains('active');

// Hide or show the mobile navigation container
const toggleMobileNav = (active) => {
  const toggleButtonDisabled = (disabled) => {
    button.setAttribute('aria-disabled', disabled);
  }

  const button = document.getElementById('toggle-mobile-nav');
  const linkWrapper = button.nextElementSibling;
  const timeline = gsap.timeline({ paused: true, onComplete: toggleButtonDisabled, onCompleteParams: ["false"] });

  toggleButtonDisabled(true);

  if (active) {
    button.innerHTML = 'x';

    timeline.to(linkWrapper, { zIndex: 2, duration: 0, onComplete: () => nav.classList.add('active') })
      .to(linkWrapper, { opacity: 1, duration: 0.5 });

  } else if (navActive()) {
    button.innerHTML = '=';

    timeline.to(linkWrapper, { opacity: 0, duration: 0.5 })
      .to(linkWrapper, { zIndex: 0, duration: 0, onComplete: () => nav.classList.remove('active') });
  }

  timeline.play();
};

// Hide mobile navigation on resize
window.addEventListener('resize', (_e) => {
  nav.firstElementChild.innerHTML = '=';
  nav.classList.remove('active');
  nav.querySelector('.link-wrap').style.opacity = 0;
});

// Hide or show the mobile navigation
document.getElementById('toggle-mobile-nav').addEventListener('click', (_e) => {
  toggleMobileNav(!navActive());
});

export { toggleMobileNav };
