import * as THREE from "three";
import Experience from "../Experience.js";

export default class Environment {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.debug = this.experience.debug;

    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("environment");
    }

    this.setEnvironmentMap();
  }

  setEnvironmentMap() {
    // TODO - move this logic to Resources
    const cubeTextureLoader = new THREE.CubeTextureLoader();
    const colorCanvas = document.createElement("canvas");

    colorCanvas.width = 1024;
    colorCanvas.height = 1024;

    const ctx = colorCanvas.getContext("2d");

    ctx.beginPath();
    ctx.rect(0, 0, 1024, 1024);
    ctx.fillStyle = "#ccc";
    ctx.fill();

    const colorURL = colorCanvas.toDataURL();

    const environmentMap = cubeTextureLoader.load([
      colorURL,
      colorURL,
      colorURL,
      colorURL,
      colorURL,
      colorURL,
    ]);

    environmentMap.encoding = THREE.sRGBEncoding;

    this.scene.environment = environmentMap;
  }
}
