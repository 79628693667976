import { toggleMobileNav } from "./navigation.js";

const themeColors = [
  "#00b8b0",
  "#12767a",
  "#5e7bee",
  "#f0047c",
  "#f766a6",
  "#ff7c5a",
  "#ffa200",
  "#2e86ab",
  "indigo",
];

const isDarkMode = () => {
  return document.getElementById("dark-mode-toggle").checked;
};

const resetView = () => {
  document.body.scrollTo({ top: 0 });

  toggleMobileNav(false);

  if (window.experience) window.experience.destroy();
};

const setThemeColor = (el) => {
  const themeColor = sessionStorage.getItem("theme-color");

  if (!themeColor) {
    return;
  }

  el.style = `--theme-color: ${themeColor}`;
};

export { isDarkMode, resetView, setThemeColor, themeColors };
