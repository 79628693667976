import gsap from 'gsap';
import { isDarkMode } from './util';
import { toggleMobileNav } from './navigation.js';

const darkModeToggle = document.getElementById('dark-mode-toggle');
const doc = document.getElementsByTagName('html')[0];
const body = doc.getElementsByTagName('body')[0];
const header = doc.getElementsByTagName('header')[0];
const footer = doc.getElementsByTagName('footer')[0];
const mobileMenu = doc.querySelector(".link-wrap")[0];

const userDark = () => {
  if (sessionStorage.getItem("dark-mode")) {
    return sessionStorage.getItem("dark-mode") === "true";
  } else {
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
}

const darkModeOn = () => {
  doc.classList.add('dark-mode');
  sessionStorage.setItem("dark-mode", true);
}

const darkModeOff = () => {
  doc.classList.remove('dark-mode');
  sessionStorage.setItem("dark-mode", false);
}

const toggleDarkMode = (darkMode) => {
  const timeline = gsap.timeline({ paused: true });

  toggleMobileNav(false);

  timeline.add('start');

  if (darkMode) {
    timeline
      .to(mobileMenu, { backgroundColor: '#000000', color: '#ffffff' }, 'start')
      .to(body, { backgroundColor: '#000000', color: '#ffffff' }, 'start')
      .to(header, { backgroundColor: '#000000', color: '#ffffff', onStart: () => darkModeOn() }, 'start')
      .to(footer, { backgroundColor: '#000000', color: '#ffffff' }, 'start')
  } else {

    timeline
      .to(mobileMenu, { backgroundColor: '#ffffff', color: '#000000' }, 'start')
      .to(body, { backgroundColor: '#ffffff', color: '#000000' }, 'start')
      .to(header, { backgroundColor: '#ffffff', color: '#000000', onStart: () => darkModeOff() }, 'start')
      .to(footer, { backgroundColor: '#ffffff', color: '#000000' }, 'start')
  }

  timeline.play();
};

if (isDarkMode() || userDark()) {
  doc.classList.add("dark-mode");

  darkModeToggle.checked = true;

  footer.style.backgroundColor = "rgba(0, 0, 0, 1)";
  header.style.backgroundColor = "rgba(0, 0, 0, 1)";
}

darkModeToggle.addEventListener('change', function() {
  toggleDarkMode(this.checked);
});
