import * as THREE from "three";
import Experience from "../Experience.js";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry.js";
import TextMaterial from "../materials/Text.js";
import { isDarkMode } from "../../../../javascript/util.js";

export default class Text {
  constructor(textString) {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;
    this.time = this.experience.time;
    this.debug = this.experience.debug;

    if (this.debug.active) {
      this.debugFolder = this.debug.ui.addFolder("text");
    }

    this.resource = this.resources.items.courierJSON;

    this.generateText(textString);

    this.time.on("tick", () => {
      this.mesh.material.uniforms.uTime.value = this.time.elapsed / 1000;
    });

    document.getElementById("dark-mode-toggle").addEventListener("change", () => {
      this.mesh.material.uniforms.uColor.value = this.setTextColor();
    });
  }

  generateText(textString) {
    let fontSettings = {
      font: this.resource,
      size: 0.1,
      height: 0.01,
      curveSegments: 10,
    };

    const textGeometry = new TextGeometry(textString, fontSettings);

    textGeometry.center();

    const textColor = this.setTextColor();
    const textMaterial = new TextMaterial(textColor);
    const mesh = new THREE.Mesh(textGeometry, textMaterial);

    mesh.material.uniforms.uColor.value = textColor;
    mesh.name = "text";

    this.mesh = mesh;
    this.scene.add(mesh);
  }

  setTextColor = () => {
    return {
      r: Number(isDarkMode()),
      g: Number(isDarkMode()),
      b: Number(isDarkMode()),
    };
  };
}
