import gsap from 'gsap';
import Experience from "../Experience.js";
import Environment from "./Environment.js";
import Container from "./Container.js";

import { scaleMeshes } from "../../../../javascript/threejs/Mesh.js";

export default class World {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    this.resources.on("ready", () => {
      this.environment = new Environment();
      this.model = new Container();
      this.meshes = this.setMeshes();

      scaleMeshes(this.meshes, { maxScale: 0.6 });

      gsap.to(this.experience.canvas, { opacity: 1, duration: 1.5 });
    });
  }

  setMeshes() {
    return ([
      this.scene.children.find((child) => child.name === "Scene")
    ])
  }

  resize() {
    scaleMeshes(this.meshes, { minScale: 0.6, maxScale: 0.6 });
  }

  update() {}
}
