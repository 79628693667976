import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import EventEmitter from "./EventEmitter.js";

export default class Resources extends EventEmitter {
  constructor(sources) {
    super();

    this.sources = sources;

    this.items = {};
    this.toLoad = this.sources.length;
    this.loaded = 0;

    this.setLoaders();
    this.startLoading();
  }

  setLoaders() {
    const loadingBarElement = document.querySelector(".loading-bar");
    const loadingManager = new THREE.LoadingManager(
      () => {
        window.setTimeout(() => {
          if (loadingBarElement) {
            loadingBarElement.classList.add("ended");
            loadingBarElement.style.transform = "";
          }
        }, 500);
      },

      (itemUrl, itemsLoaded, itemsTotal) => {
        const progressRatio = itemsLoaded / itemsTotal;

        if (loadingBarElement) {
          loadingBarElement.style.transform = `scaleX(${progressRatio})`;
        }
      }
    );

    this.loaders = {};
    this.loaders.fontLoader = new FontLoader(loadingManager);
    this.loaders.gltfLoader = new GLTFLoader(loadingManager);
    this.loaders.textureLoader = new THREE.TextureLoader(loadingManager);
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
    this.loaders.cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
  }

  startLoading() {
    for (const source of this.sources) {
      if (source.type === "gltf") {
        this.loaders.gltfLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "texture") {
        this.loaders.textureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "cubeTexture") {
        this.loaders.cubeTextureLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      } else if (source.type === "font") {
        this.loaders.fontLoader.load(source.path, (file) => {
          this.sourceLoaded(source, file);
        });
      }
    }
  }

  sourceLoaded(source, file) {
    this.items[source.name] = file;

    this.loaded++;

    if (this.loaded === this.toLoad) {
      this.trigger("ready");
    }
  }
}
