import { resetView } from "../../util.js";

class Services {
  constructor() {
    this.namespace = "services";
  }

  beforeEnter = (_data) => {
    resetView();
  };
}

export default new Services();
