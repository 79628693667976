import * as THREE from "three";
import Debug from "../../../javascript/threejs/Debug.js";
import Sizes from "../../../javascript/threejs/Sizes.js";
import Events from "../../../javascript/threejs/Events.js";
import Time from "../../../javascript/threejs/Time.js";
import Resources from "../../../javascript/threejs/Resources.js";
import World from "./canvas/World.js";
import Camera from "./Camera.js";
import Renderer from "./Renderer.js";
import sources from "./sources.js";

let instance = null;

export default class Experience {
  constructor(_canvas) {
    if (instance) {
      return instance;
    }

    instance = this;

    window.experience = this;
    this.canvas = _canvas;
    this.debug = new Debug();
    this.sizes = new Sizes();
    this.events = new Events();
    this.time = new Time();
    this.scene = new THREE.Scene();
    this.resources = new Resources(sources);
    this.camera = new Camera();
    this.renderer = new Renderer();
    this.world = new World();
    this.renderer = new Renderer();

    this.events.on("click", (e) => {
      this.world.click(e);
    });

    this.events.on("mousemove", (e) => {
      this.world.mousemove(e);
    });

    this.sizes.on("resize", () => {
      this.resize();
    });

    this.time.on("tick", () => {
      this.update();
    });
  }

  resize() {
    this.camera.resize();
    this.renderer.resize();
    this.world.resize();
  }

  update() {
    this.camera.update();
    this.world.update();
    this.renderer.update();
  }

  destroy() {
    instance = null;

    this.sizes.off("resize");
    this.time.off("tick");

    this.scene.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.geometry.dispose();

        for (const key in child.material) {
          const value = child.material[key];

          if (value && typeof value.dispose === "function") {
            value.dispose();
          }
        }
      }
    });

    if (this.camera.controls) this.camera.controls.dispose();

    this.renderer.instance.dispose();

    if (this.debug.active) this.debug.ui.destroy();
  }
}
